import cn from 'classnames';

import type {Mode} from '@/components/base/types';
import {twMerge} from '@/stylesheets/twMerge';

type IconProps = {
  className: string;
  symbolClassName: string;
};

const CloseIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    viewBox="0 0 64 64"
    className={twMerge('w-16 h-16 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fillOpacity="0.2" />
    <circle cx="32" cy="32" r="20" />
    <path
      d="M26 26L38 38"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
    <path
      d="M38 26L26 38"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
  </svg>
);

const CompactCloseIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    className={twMerge('w-6 h-6 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 20.49918,3.5008202 3.5008202,20.49918 Z"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
    <path
      d="M 3.5008202,3.5008202 20.49918,20.49918 Z"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
  </svg>
);

const BaseCloseIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={twMerge('w-5 h-5 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M 17.554827,2.4451734 2.4451734,17.554827 Z"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
    <path
      d="M 2.4451734,2.4451734 17.554827,17.554827 Z"
      strokeWidth="2"
      strokeLinecap="square"
      className={symbolClassName}
    />
  </svg>
);

export const Close = ({
  mode,
  isCompact = false,
  isBase = false,
  className,
}: {
  mode: Mode;
  isCompact?: boolean;
  isBase?: boolean;
  className?: string;
}) => {
  const Icon = isBase
    ? BaseCloseIcon
    : isCompact
      ? CompactCloseIcon
      : CloseIcon;

  const isCompactOrBase = isCompact || isBase;

  return (
    <Icon
      className={twMerge(
        cn(
          {
            'fill-checklist-light-close': mode === 'light',
            'fill-checklist-dark-close': mode === 'dark',
          },
          className,
        ),
      )}
      symbolClassName={cn({
        'stroke-white': mode === 'light' && !isCompactOrBase,
        'stroke-checklist-light-close': mode === 'light' && isCompactOrBase,
        'stroke-black': mode === 'dark' && !isCompactOrBase,
        'stroke-checklist-dark-close': mode === 'dark' && isCompactOrBase,
      })}
    />
  );
};
