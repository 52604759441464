import cn from 'classnames';

import type {Mode} from '@/components/base/types';
import {twMerge} from '@/stylesheets/twMerge';

type IconProps = {
  className: string;
  symbolClassName: string;
};

const CheckmarkIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    viewBox="0 0 64 64"
    className={twMerge('w-16 h-16 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32" cy="32" r="32" fillOpacity="0.2" />
    <circle cx="32" cy="32" r="20" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.4428 36.2766L39.8391 24.5044L41.2445 25.7455L29.5627 38.9734L23.2302 32.9722L24.52 31.6112L29.4428 36.2766Z"
      className={symbolClassName}
    />
  </svg>
);

const CompactCheckmarkIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={twMerge('w-6 h-6 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65697 16.8428L20.876 3.00684L23.124 4.99284L8.84997 21.1568L0.969971 13.6898L3.03297 11.5118L8.65697 16.8428Z"
      className={symbolClassName}
    />
  </svg>
);

const BaseCheckmarkIcon = ({className, symbolClassName}: IconProps) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    className={twMerge('w-5 h-5 shrink-0', className)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M 7.2456354,13.868264 17.173516,2.6266398 19,4.2402515 7.4024466,17.37336 1.0000003,11.306473 2.6761732,9.5368632 Z"
      className={symbolClassName}
    />
  </svg>
);

export const Checkmark = ({
  mode,
  isCompact = false,
  isBase = false,
  className,
}: {
  mode: Mode;
  isCompact?: boolean;
  isBase?: boolean;
  className?: string;
}) => {
  const Icon = isBase
    ? BaseCheckmarkIcon
    : isCompact
      ? CompactCheckmarkIcon
      : CheckmarkIcon;

  const isCompactOrBase = isCompact || isBase;

  return (
    <Icon
      className={twMerge(
        cn(
          {
            'fill-checklist-light-checkmark': mode === 'light',
            'fill-checklist-dark-checkmark': mode === 'dark',
          },
          className,
        ),
      )}
      symbolClassName={cn({
        'fill-white': mode === 'light' && !isCompactOrBase,
        'fill-black': mode === 'dark' && !isCompactOrBase,
      })}
    />
  );
};
